'use strict';

// Store specific custom vendor builds.
require('store-jquery-ui');
require('store-modernizr');

var formWidget = require('form-widget');
var dynamicVideoBanners = require('./dynamic-video-banners');
var pageBuilderWidgetFactory = require('./page-builder-widget-factory');

module.exports = {

	// Expose new browserify modules to legacy js.
	formWidget: formWidget,
	dynamicVideoBanners: dynamicVideoBanners,
	pageBuilderWidgetFactory: pageBuilderWidgetFactory

};
