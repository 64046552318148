'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var imageProportions = require('image-proportion');
var throttle = require('lodash/function/throttle');

var videoProportions = {};
var dom;
var settings = {
	bannerHeight: 0,
	bannerMarkup: '',
	container: undefined,
	isFullscreenBanner: false
};

var setupDom = function() {

	dom = {};
	dom.banner = $('#banner');
	dom.window = $(window);

};

var addEventHandlers = function() {

	dom.window.on('resize', throttle(function() {
		determineVideoDimensions();
		performVideoAdjustments();
	}, 100));

};

var cacheInjectedElements = function() {

	dom.iframe = $('#bannerFrame');
	dom.videoWrapper = dom.banner.find('.video-wrapper');

};

var injectBanner = function() {

	dom.banner.append(settings.bannerMarkup);

};

var performVideoAdjustments = function() {

	dom.iframe.height(videoProportions.height);
	dom.iframe.width(videoProportions.width);
	dom.iframe.css({
		'transform': 'translate(' + videoProportions.xOffset + 'px, ' + videoProportions.yOffset + 'px)'
	});

	if (settings.isFullscreenBanner) {
		dom.videoWrapper.height(settings.bannerHeight);
	}

};

var determineVideoDimensions = function() {

	var width = settings.container ? $(settings.container).outerWidth() : document.documentElement.clientWidth;

	videoProportions = imageProportions.cover(
		parseInt(dom.iframe.attr("width")),
		parseInt(dom.iframe.attr("height")),
		width,
		settings.bannerHeight
	);

};

var overrideBannerHeight = function() {

	if (settings.isFullscreenBanner) {
		settings.bannerHeight = document.documentElement.clientHeight;
	}

};

var init = function(options) {

	$.extend(settings, options);

	overrideBannerHeight();

	setupDom();

	injectBanner();

	cacheInjectedElements();

	addEventHandlers();

	determineVideoDimensions();

	performVideoAdjustments();

};

module.exports = {
	init: init
};
