'use strict';

var imageProportion = require('image-proportion');

var hasImage = function(data) {

	return !!data.width;

};

var getLoader = function(data) {

	if (hasImage(data)) {

		return '<div class="image-grid__loader"></div>';

	}

	return '';

};

var getUrl = function(imageSize, data) {

	return data.baseUrl + data.sizes[imageSize] + data.ext;

};

var getLazyAttr = function(imageSize, data) {

	// Make sure this item has an image.
	if (hasImage(data)) {

		return ' data-lazy-image="' + getUrl(imageSize, data) + '"';

	}

	return '';

};

var getLinkClass = function(isLink) {

	if (isLink) {

		return ' image-grid__item--link';

	}

	return '';

};

var getDescription = function(isTextHidden, data) {

	// if (isTextHidden && data.description.length > 50) {

	// 	return data.description.substr(0, 50) + '...';

	// }

	return data.description;

};

var template = function(imageSize, idx, options) {

	var data = options.data[idx];
	var t = '<div class="grid__cell image-grid__cell">' +
				'<div class="image-grid__item image-grid__item--' + imageProportion.getOrientation(data.width, data.height) + getLinkClass(data.isLink) + '" data-image-grid-index="' + data.index + '">' +
					'<div class="image-grid__image-wrapper">' +
						'<div class="image-grid__image" title="' + data.title + '"' + getLazyAttr(imageSize, data) + (options.layout === 'stack' || options.layout === 'stackmini' ? ' style="padding-bottom:' + imageProportion.scaleByWidth(data.width, data.height, 100) + '%;"' : '') + '></div>' +
						getLoader(data) +
					'</div>' +
					'<div class="image-grid__text-wrapper">' +
						'<div class="image-grid__title">' + data.title + '</div>' +
						'<div class="image-grid__desc">' + getDescription(options.isTextHidden, data) + '</div>' +
					'</div>' +
				'</div>' +
			'</div>';

	return t;

};

module.exports = {

	template: template,
	getUrl: getUrl

};
