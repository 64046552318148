'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var imageGrid = require('image-grid-3.0.0');
var triggerCustomEvent = require('trigger-custom-event');

var instanceCount = 0;

var create = function(pageBuilderWidgetId, imageGridData) {

	var dom;
	var imageGridInstanceMap;
	var instanceId;

	var getImageGridInstance = function(imageGridId) {

		return imageGridInstanceMap[imageGridId];

	};

	var getImageGridId = function($item) {

		return $item.closest('.image-grid').attr('data-image-grid-id');

	};

	var triggerSlideshowRequestEvent = function(imageGridId, imageGridInstanceData, itemIndex) {

		triggerCustomEvent('page_builder_widget_slideshow_requested', {
			target: dom.pageBuilderWidget,
			data: {
				builderFieldInstanceId: imageGridId.split('-')[1],
				imageGridInstanceData: imageGridInstanceData,
				itemIndex: itemIndex
			}
		});

	};

	/*var onLinkedStackItemClick = function() {

		var $this = $(this);
		var imageGridId = getImageGridId($this);
		var imageGridInstance = getImageGridInstance(imageGridId);
		var itemIndex = imageGridInstance.getItemIndex($this);
		var itemLinkUrl = imageGridInstance.getData()[itemIndex].linkUrl;

		window.open(itemLinkUrl, '_blank');

	};

	var onTextHiddenItemClick = function() {

		var $this = $(this);
		var imageGridId = getImageGridId($this);
		var imageGridInstance = getImageGridInstance(imageGridId);
		var itemIndex = imageGridInstance.getItemIndex($this);

		triggerSlideshowRequestEvent(imageGridId, imageGridInstance.getData(), itemIndex);

	};

	var onNonStackNonHiddenTextItemClick = function(e) {

		var $target = $(e.target);
		var $this = $(this);
		var imageGridId = getImageGridId($this);
		var imageGridInstance = getImageGridInstance(imageGridId);
		var imageGridInstanceData = imageGridInstance.getData();
		var itemIndex = imageGridInstance.getItemIndex($this);
		var itemLinkUrl = imageGridInstanceData[itemIndex].linkUrl;

		// Load slideshow if clicked on the image or if there is no link setup
		if ($target.is('.image-grid__image') || !itemLinkUrl) {

			triggerSlideshowRequestEvent(imageGridId, imageGridInstanceData, itemIndex);

		} else {

			window.open(itemLinkUrl, '_blank');

		}

	};*/

	var onLinkedItemClick = function() {

		var $this = $(this);
		var imageGridId = getImageGridId($this);
		var imageGridInstance = getImageGridInstance(imageGridId);
		var imageGridInstanceData = imageGridInstance.getData();
		var itemIndex = imageGridInstance.getItemIndex($this);
		var itemLinkUrl = imageGridInstanceData[itemIndex].linkUrl;
		var itemLinkMenuId = imageGridInstanceData[itemIndex].linkMenuId;
		var showImageDetailOnClick = $this.closest('[data-show-image-detail-on-click]').attr('data-show-image-detail-on-click') === '1';
		var target = '_self';

		if (showImageDetailOnClick) {

			triggerSlideshowRequestEvent(imageGridId, imageGridInstanceData, itemIndex);

		} else {

			// Open in new tab if we're not linking to an internal page
			if (!itemLinkMenuId) {

				target = '_blank';

			}

			window.open(itemLinkUrl, target);

		}

	};

	var setupDom = function() {

		dom = {
			pageBuilderWidget: $('#pageBuilderWidget' + pageBuilderWidgetId)
		};

	};

	var addEventHandlers = function() {

		dom.pageBuilderWidget.on('click.page-builder-widget-' + instanceId, '.image-grid__item--link', onLinkedItemClick);
			// Stack layout item clicks
			// .on('click.page-builder-widget-' + instanceId, '.image-grid--stack .image-grid__item--link', onLinkedStackItemClick)
			// Hidden text style item clicks
			// .on('click.page-builder-widget-' + instanceId, '.image-grid--text-hidden .image-grid__item', onTextHiddenItemClick)
			// Item clicks not on stack layout or hidden text style
			// .on('click.page-builder-widget-' + instanceId, '.image-grid:not(.image-grid--stack):not(.image-grid--text-hidden) .image-grid__item', onNonStackNonHiddenTextItemClick);

	};

	var destroy = function() {

		dom.pageBuilderWidget.off('.page-builder-widget-' + instanceId);
		dom = null;
		imageGridInstanceMap = null;
		instanceId = null;

	};

	var init = function() {

		instanceId = instanceCount++;
		imageGridInstanceMap = {};

		setupDom();
		addEventHandlers();

		// Create image-grid instances for each grid in this page builder widget
		imageGridData.forEach(function(instanceData) {

			imageGridInstanceMap[pageBuilderWidgetId + '-' + instanceData.instanceId] = imageGrid({
				target: '#imageGrid' + pageBuilderWidgetId + '-' + instanceData.instanceId,
				data: instanceData.data,
				lazyOffset: 500,
				lazyDebounceWait: 0
			});

		});

	};

	init();

	return {
		destroy: destroy
	};

};

module.exports = {
	create: create
};
