'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var ScrollWatch = require('scrollwatch');

var throttle = require('lodash/function/throttle');
var loadImage = require('load-image');

var instanceCount = 0;

var lazyImage = function(opts) {

	var swInstance;
	var dom;
	var state;
	var instanceId;

	var options = {
		target: '[data-lazy-image]',
		throttleWait: 250,
		debounceWait: 250,
		debounce: false,
		offset: 0,
		customScroller: '',
		customScrollerEvent: ''
	};

	var isCustomScroller = function() {

		return !!options.customScroller;

	};

	var isBackgroundImg = function($img) {

		return $img.is('img') ? false : true;

	};

	var cacheTargets = function() {

		dom.lazyImgs = $(options.target + ':not(.lazy-image--loaded)');

	};

	// Only used for custom js scroller. This is a "dumb" lazy loader, only native
	// scrolling supports "smart".
	var loadImages = function() {

		var containerBottom;

		cacheTargets();

		if (!dom.lazyImgs.length) {

			destroy();
			return;

		}

		containerBottom = dom.customScroller.height() + state.customScrollerYOffset;

		dom.lazyImgs.each(function(i) {

			var $img = dom.lazyImgs.eq(i);
			var topOfImg;

			topOfImg = $img.offset().top - dom.customScroller.offset().top + state.customScrollerYOffset;

			if (topOfImg < containerBottom) {

				processImg($img);

			}

		});

	};

	var setupInitialState = function() {

		state = {
			customScrollerYOffset: 0
		};

	};

	var setupDom = function() {

		dom = {};

		cacheTargets();
		dom.customScroller = $(options.customScroller);

	};

	var addEventHandlers = function() {

		dom.customScroller.off('.system-lazy-image-' + instanceId);

		dom.customScroller.on(options.customScrollerEvent + '.system-lazy-image-' + instanceId, throttle(function(e) {

			state.customScrollerYOffset = e.yOffset;
			loadImages();

		}, options.throttleWait));

	};

	var processImg = function($img) {

		var imgSrc = $img.attr('data-lazy-image');
		var isBackground = isBackgroundImg($img);

		$img.removeAttr('data-lazy-image');

		loadImage(imgSrc, function() {

			if (isBackground) {

				$img.css('background-image', 'url(' + imgSrc + ')');

			} else {

				$img.attr('src', imgSrc);

			}

			$img.addClass('lazy-image--loaded');

		});

	};

	var onElementInView = function(data) {

		processImg($(data.el));

	};

	var refresh = function() {

		console.log('lazy-load refresh()...');

		swInstance.refresh();

	};

	var destroy = function() {

		console.log('lazy-image.js: destroy()');

		if (swInstance) {

			swInstance.destroy();
			swInstance = null;

		}

		if (dom) {

			dom.customScroller.off('.system-lazy-image-' + instanceId);
			dom = null;
			state = null;

		}

		instanceId = null;

	};

	var init = function() {

		var swOptions;

		instanceId = instanceCount++;
		$.extend(options, opts);

		if (isCustomScroller()) {

			setupInitialState();
			setupDom();

			if (dom.lazyImgs.length) {

				addEventHandlers();
				loadImages();

			}

		} else {

			swOptions = {
				watch: options.target,
				watchOffset: options.offset,
				onElementInView: onElementInView
			};

			if (options.debounce) {

				swOptions.debounce = true;
				swOptions.scrollDebounce = options.debounceWait;
				swOptions.resizeDebounce = options.debounceWait;

			} else {

				swOptions.scrollThrottle = options.throttleWait;
				swOptions.resizeThrottle = options. throttleWait;

			}

			swInstance = new ScrollWatch(swOptions);

		}

	};

	init();

	return {
		destroy: destroy,
		refresh: refresh
	};

};

module.exports = lazyImage;
