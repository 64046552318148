'use strict';

var gridCellJst = require('./grid-cell-jst');

var template = function(imageSize, start, end, options) {

	var numItems = options.data.length;
	var t = '';
	var i;

	// Check if we have more cells in the data that need generated.
	if (numItems > start) {

		// Make sure we don't go over.
		if (end > numItems) {

			end = numItems;

		}

		// Generate the next batch of cells.

		for (i = start; i < end; i++) {

			t += gridCellJst.template(imageSize, i, options);

		}

	}

	return t;

};

module.exports = {

	template: template

};
