'use strict';

/*
	DATA SIGNATURE FOR USAGE (with data-attribute) should be as follows.
	Don't forget the quotes around the object keys
	<my-element class='my-super-img' data-focal-point='{ "hasFocal": #myImgData.has_focal#, "height": #myImgData.height#, "width": #myImgData.width#, "x": #myImgData.focal_x_percent#, "y": #myImgData.focal_y_percent# }'></my-element>
*/

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');
var imageProportion = require('image-proportion');
var throttle = require('throttle');

var instanceCount = 0;

var focalPoint = function(opts) {

	var dom;
	var options;
	var instanceId;

	var adjust = function() {

		dom.imgs.each(function(i) {

			var $img = dom.imgs.eq(i);
			var data = options.data && options.data[i] || $img.data('focal-point');
			var hasFocal = data.hasFocal;
			var isBackground = $img.is('img') ? false : true;
			var $container = isBackground ? $img : options.targetContainer ? $img.closest(options.targetContainer) : $img.parent();
			// Get the padding box width and height for the container.
			var containerWidth = $container.width() + parseFloat($container.css('padding-left')) + parseFloat($container.css('padding-right'));
			var containerHeight = $container.height() + parseFloat($container.css('padding-top')) + parseFloat($container.css('padding-bottom'));
			var cover = imageProportion.cover(data.width, data.height, containerWidth, containerHeight);
			var focal;
			var distanceFromCenter;
			var adjustedOffset;
			var imgCenter;
			var targetAxis;
			var targetDirection;
			var nonTargetDirection;
			var targetDimension;
			var targetOffset;
			var cssObj;

			// console.log(i, cover);

			// Determine which axis is overflowing and can be adjusted.
			if (cover.xOverflow) {

				targetAxis = 'x';
				targetDirection = 'left';
				nonTargetDirection = 'top';
				targetDimension = 'width';
				targetOffset = 'xOffset';

			} else {

				targetAxis = 'y';
				targetDirection = 'top';
				nonTargetDirection = 'left';
				targetDimension = 'height';
				targetOffset = 'yOffset';

			}

			// Center the image as a baseline, unless the image is a background
			// image b/c we are assuming it will be centered via css.
			if (!isBackground) {

				// Pull the image left or up to center.
				cssObj = {
					width: cover.width,
					height: cover.height
				};

				cssObj[targetDirection] = cover[targetOffset];

				// Reset the non target direction in case the container size has changed and the non target direction previously was the target direction
				cssObj[nonTargetDirection] = 0;

				$img.css(cssObj);

			}

			$img.attr('data-focal-point-processed', '');

			// Apply the focal point.
			if (hasFocal) {

				// Determine the scaled location of the focal point.
				focal = data[targetAxis] / 100 * cover[targetDimension];

				// How far from the center is the focal point?
				imgCenter = cover[targetDimension] / 2;
				distanceFromCenter = imgCenter - focal;

				if (Math.abs(distanceFromCenter) > Math.abs(cover[targetOffset])) {

					// Honoring the focal point would adjust the image too much.
					// Determine which direction we are trying to go and make
					// sure the adjustment doesn't leave any whitespace.

					if (distanceFromCenter > 0) {

						// We are trying to push the image too far, don't let it go
						// past the edge.
						adjustedOffset = 0;

					} else {

						// We are trying to pull the image too far, don't let it go
						// past the edge. Adding 1 to deal with browser rounding.
						// It's better to have the focal point be off by 1px than
						// pull the image too far and have a 1px gap in the container.
						adjustedOffset = Math.ceil(cover[targetOffset] * 2) + 1;

					}

				} else {

					// We can honor the focal point.
					adjustedOffset = cover[targetOffset] + distanceFromCenter;

				}

				if (isBackground) {

					if (cover.xOverflow) {

						$img.css('background-position', adjustedOffset + 'px center');

					} else {

						$img.css('background-position', 'center ' + adjustedOffset + 'px');

					}

				} else {

					$img.css(targetDirection, adjustedOffset);

				}

			}

		});

	};

	var setupDom = function() {

		dom = {};

		dom.imgs = $(options.target);

	};

	var setupOptions = function(o) {

		options = {
			target: '[data-focal-point]',
			targetContainer: '',
			// Allow data to be passed in or read from the DOM.
			data: null
		};

		$.extend(options, o);

	};

	var addEventHandlers = function() {

		domCache.window.off('.focal-point-' + instanceId);

		domCache.window.on('resize.focal-point-' + instanceId, throttle(adjust, 100));

	};

	var init = function() {

		instanceId = instanceCount++;
		setupOptions(opts);
		setupDom();
		addEventHandlers();
		adjust();

	};

	var destroy = function() {

		domCache.window.off('.focal-point-' + instanceId);
		dom = null;
		options = null;
		instanceId = null;

	};

	init();

	return {
		destroy: destroy,
		adjust: adjust
	};

};

module.exports = focalPoint;
