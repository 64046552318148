'use strict';

var sortBy = require('lodash/collection/sortBy');

var domCache = require('dom-cache');
var stateDetection = require('state-detection');
var imageProportion = require('image-proportion');

var instanceCount = 0;

var masonry = function(target, $grid, isTextHidden, data) {

	var dom;
	var state;
	var options;
	var gridStateDetection;
	var instanceId;

	var getCells = function() {

		return dom.grid.find('.image-grid__cell');

	};

	var getNumCols = function() {

		return parseInt(gridStateDetection.getState(), 10);

	};

	var getColWidth = function() {

		return (dom.grid.width() - options.gutterSize * (state.numCols - 1)) / state.numCols;

	};

	var getGutterSize = function() {

		return parseInt(dom.grid.css('margin-left'), 10);

	};

	var getItemIndex = function(item) {

		return parseInt(item.getAttribute('data-image-grid-index'), 10);

	};

	var cacheCells = function() {

		dom.cells = getCells();

	};

	var setupDom = function() {

		dom = {};

		dom.grid = $grid;
		cacheCells();

	};

	var setupInitialState = function() {

		state = {};

		// Store a data object for each column.
		state.colData = [];
		state.numCols = getNumCols();
		state.colWidth = getColWidth();

	};

	var toggleSorting = function(classState) {

		dom.grid.toggleClass('image-grid--sorting', classState);

	};

	var addItems = function($cells) {

		$cells.each(function(i) {

			var $cell = $cells.eq(i);
			var $item = $cell.find('.image-grid__item');
			var $img = $item.find('.image-grid__image');
			var itemIndex = getItemIndex($item[0]);
			var itemData = options.data[itemIndex];
			var targetCol;
			var scaledHeight;

			// Determine the image height by proportionally scaling it based
			// on the column width. The image will fill the column width.
			scaledHeight = imageProportion.scaleByWidth(itemData.width, itemData.height, state.colWidth);

			// $img.css('padding-bottom', 'calc(100% * ' + itemData.height + ' / ' + itemData.width + ')');
			$img.css('padding-bottom', (imageProportion.scaleByWidth(itemData.width, itemData.height, 100)) + '%');

			if (itemIndex < state.numCols) {

				// Leave the first "row" of images where they are.

				targetCol = itemIndex;

				// Create a data object for each column. Store the index and
				// a running total of the column height. We'll use the index
				// as an easy pointer afer the columns are sorted.
				state.colData[targetCol] = {
					col: targetCol,
					height: 0
				};

			} else {

				// Sort colData to find the column with the smallest
				// height. The current item should be moved to this column.

				targetCol = sortBy(state.colData, 'height')[0].col;

				// Move the item into the shortest column.
				dom.cells.eq(targetCol).append($item);

				// Remove empty cell from the dom.
				$cell.remove();

			}

			// Increment the height for this column.

			if (!options.isTextHidden) {

				// Item height equals image height and text height.
				scaledHeight += $item.height();

			}

			state.colData[targetCol].height += scaledHeight;

			// Save the index of the last item we have. This info will be provided
			// in the infinite scroll event broadcast by this module. Application
			// modules will use this data formulate requests for more grid images.
			state.lastItemIndex = itemIndex;

		});

	};

	var sortItems = function() {

		toggleSorting(true);

		setupInitialState();

		addItems(dom.cells);

		toggleSorting(false);

	};

	var unsortItems = function() {

		var $items;
		var $emptyCell;

		toggleSorting(true);

		$emptyCell = dom.cells.eq(0).clone().empty();

		$items = dom.grid.find('.image-grid__item');

		$items.sort(function(a, b) {

			var aIndex = getItemIndex(a);
			var bIndex = getItemIndex(b);

			if (aIndex > bIndex) {

				return 1;

			}

			if (aIndex < bIndex) {

				return -1;

			}

			return 0;

		});

		dom.grid.html($items);

		$items.wrap($emptyCell);

		cacheCells();

		sortItems();

	};

	var addEventHandlers = function() {

		domCache.document.on('image_grid_state_change.system-image-grid-masonry-' + instanceId, function() {

			// The number of grid columns has changed. We need to start over and
			// resort the masonry grid.

			console.log('column change: resort masonry layout...');

			unsortItems();

		});

	};

	var init = function() {

		instanceId = instanceCount++;

		setupDom($grid);

		options = {};

		options.data = data;
		options.isTextHidden = isTextHidden;
		options.gutterSize = getGutterSize();

		// Setup state-detection for the image grid, the event image_grid_state_change
		// will fire any time the number of grid columns changes. This is triggered by
		// changing the ::before content via css.
		gridStateDetection = stateDetection({
			target: target,
			eventName: 'image_grid_state_change'
		});
		addEventHandlers();
		sortItems();

	};

	var destroy = function() {

		domCache.document.off('.system-image-grid-masonry-' + instanceId);
		dom = null;
		state = null;
		instanceId = null;
		gridStateDetection.destroy();
		gridStateDetection = null;

	};

	init();

	return {
		destroy: destroy,
		addItems: function() {

			addItems(getCells().slice(state.numCols));

		},
		getLastItemIndex: function() {

			return state.lastItemIndex;

		}
	};

};

module.exports = masonry;
