'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var ScrollWatch = require('scrollwatch');
var debounce = require('lodash/function/debounce');
var defer = require('lodash/function/defer');

var domCache = require('dom-cache');
var lazyImage = require('lazy-image-2.0.0');
var focalPoint = require('focal-point-2.0.0');
// Note: if you want this to work, you must provide your own, app specific css
// based on the app breakpoints.
var bodyStateDetection = require('body-state-detection');

var masonry = require('./masonry');
var gridCellsJst = require('./grid-cells-jst');
var gridCellJst = require('./grid-cell-jst');

var instanceCount = 0;

var imageGrid = function(opts) {

	var dom;
	var state;
	var options;
	var lazyImageInstance;
	var debouncedLazyImageRefresh;
	var focalPointInstance;
	var offscreenImagesWatcher;
	var infiniteScrollWatcher;
	var masonryInstance;
	var instanceId;

	// Give each image size a weighted value for comparison purposes.
	var imageSizeMap = {
		'small': 0,
		'medium': 1,
		'large': 2,
		'xlarge': 3
	};

	var isFireFox = function() {

		var ua = window.navigator.userAgent.toLowerCase();

		return ua.indexOf('gecko') !== -1 && ua.indexOf('firefox') !== -1;

	};

	var setupOptions = function() {

		var defaultDataOptions = {
			isLink: true,
			sizes: {},
			width: 0,
			height: 0,
			hasFocal: false,
			x: 0,
			y: 0
		};

		// Merge data options.
		opts.data.forEach(function(obj, i) {

			opts.data[i] = $.extend({curState: bodyStateDetection.getState()}, defaultDataOptions, obj);

		});

		options = {
			target: '.image-grid',
			// Can ONLY be used with native scrolling
			offscreenImages: {
				remove: false,
				removeOnlyForFireFox: false,
				// ScrollWatch options
				options: {}
			},
			// Can ONLY be used with native scrolling
			infiniteScroll: {
				enable: false,
				// ScrollWatch options
				options: {}
			},
			data: [],
			viewportMap: {
				small: {
					batchSize: 10,
					gridImageSize: 'medium'
				},
				medium: {
					batchSize: 10,
					gridImageSize: 'medium'
				},
				large: {
					batchSize: 15,
					gridImageSize: 'large'
				},
				xlarge: {
					batchSize: 20,
					gridImageSize: 'xlarge'
				},
				xxlarge: {
					batchSize: 25,
					gridImageSize: 'xlarge'
				}
			},
			lazyDebounceWait: 250,
			lazyOffset: 0,
			// Proxy lazy-image options.
			customScroller: '',
			customScrollerEvent: ''
		};

		$.extend(options, dom.grid.data('image-grid-options'), opts);

	};

	var getItemIndex = function($item) {

		$item = $item.is('.image-grid__item') ? $item : $item.closest('.image-grid__item');

		return parseInt($item.attr('data-image-grid-index'), 10);

	};

	var getItemByIndex = function(index) {

		return dom.grid.find('[data-image-grid-index="' + index + '"]');

	};

	var getItems = function() {

		return dom.grid.find('.image-grid__item');

	};

	var getData = function() {

		return options.data;

	};

	var isMasonry = function() {

		return options.isMasonry;

	};

	var setupInitialState = function() {

		state = {};

		state.lastItemIndex = null;

	};

	var saveLastItemIndex = function() {

		if (isMasonry()) {

			state.lastItemIndex = masonryInstance.getLastItemIndex();

		} else {

			state.lastItemIndex = getItemIndex(getItems().last());

		}

	};

	var addEventHandlers = function() {

		// This will be fired whenever the ::before content of body changes, which
		// would happen through a css media query
		domCache.document.on('device_state_changed.system-image-grid-' + instanceId, function(e) {

			if (bodyStateDetection.wasUpsized(e.oldState, e.newState)) {

				// We just hit a larger breakpoint, upgrade images if necessary.

				options.data.forEach(function(obj) {

					var oldSize = obj.sizes[options.viewportMap[e.oldState].gridImageSize];
					var newSize = obj.sizes[options.viewportMap[e.newState].gridImageSize];

					if (oldSize !== newSize) {

						// This image supports a larger version at this breakpoint.

						// Make sure the new state isn't smaller than a previously
						// visited state. For example, if this image used sizes of
						// small, medium and large at each of those respective states,
						// we wouldn't want to load the medium size when going from
						// the small to medium state, if we have already visited the large
						// state, and thus loaded the large size, which is better than
						// medium.
						if (imageSizeMap[options.viewportMap[e.newState].gridImageSize] > imageSizeMap[options.viewportMap[obj.curState].gridImageSize]) {

							// Track the current viewport state used for each image.
							obj.curState = e.newState;

							getItemByIndex(obj.index)
								.find('.image-grid__image')
									.removeClass('scroll-watch-in-view scroll-watch-ignore lazy-image--loaded')
									.attr('data-lazy-image', gridCellJst.getUrl(e.newState, obj));

						}


					}

				});

			} else {

				// We just hit a smaller breakpoint, downgrade images we haven't
				// downloaded yet.

				dom.grid.find('[data-lazy-image]').each(function() {

					var $this = $(this);
					var $item = $this.closest('.image-grid__item');
					var itemIndex = getItemIndex($item);
					var imgObj = options.data[itemIndex];
					var oldSize = imgObj.sizes[options.viewportMap[e.oldState].gridImageSize];
					var newSize = imgObj.sizes[options.viewportMap[e.newState].gridImageSize];

					if (oldSize !== newSize) {

						imgObj.curState = e.newState;

						$this.attr('data-lazy-image', gridCellJst.getUrl(e.newState, imgObj));

					}

				});

			}

			lazyImageInstance.refresh();

			if (offscreenImagesWatcher) {

				offscreenImagesWatcher.refresh();

			}

			if (infiniteScrollWatcher) {

				infiniteScrollWatcher.refresh();

			}

		});

	};

	var generateCells = function() {

		var deviceSize = bodyStateDetection.getState();
		var start;
		var end;

		if (options.infiniteScroll.enable) {

			// Check if this is the initial batch of cells
			start = !state ? 0 : state.lastItemIndex + 1;
			end = start + options.viewportMap[deviceSize].batchSize;

		} else {

			// No infinite scroll, generate all cells at once
			start = 0;
			end = options.data.length;

		}

		return gridCellsJst.template(
			options.viewportMap[deviceSize].gridImageSize,
			start,
			end,
			options
		);

	};

	var init = function() {

		instanceId = instanceCount++;

		dom = {};
		dom.grid = $(opts.target || '.image-grid');

		setupOptions(opts);
		addEventHandlers();

		console.log('system image-grid options: ', options);

		// Generate and inject initial batch of cells.
		dom.grid.html(generateCells());

		if (isMasonry()) {

			masonryInstance = masonry(options.target, dom.grid, options.isTextHidden, options.data);

			// This must be added after masonry is created and it's only needed for
			// the masonry layout, so we'll set it up here instead of in addEventHandlers()
			domCache.document.on('image_grid_state_change.system-image-grid-' + instanceId, function() {

				// The number of grid columns has changed and the grid has been resorted

				lazyImageInstance.refresh();

				if (offscreenImagesWatcher) {

					offscreenImagesWatcher.refresh();

				}

				if (infiniteScrollWatcher) {

					infiniteScrollWatcher.refresh();

				}

			});

		} else if (options.layout !== 'stack') {

			// Masonry and stack layouts show the full image, so wouldn't need
			// focal point for them
			focalPointInstance = focalPoint({
				target: options.target + ' .image-grid__image',
				data: options.data
			});

		}

		lazyImageInstance = lazyImage({
			target: options.target + ' .image-grid__image',
			offset: options.lazyOffset,
			debounce: true,
			debounceWait: options.lazyDebounceWait,
			customScroller: options.customScroller,
			customScrollerEvent: options.customScrollerEvent
		});

		if (options.offscreenImages.remove) {

			if (!options.offscreenImages.removeOnlyForFireFox || isFireFox()) {

				// Remove images when they are not viewable to increase performance and fix FF memory leaks

				dom.grid.addClass('image-grid--remove-offscreen-images');

				offscreenImagesWatcher = new ScrollWatch($.extend(true, {
					watch: options.target + ' .image-grid__image-wrapper',
					watchOnce: false,
					inViewClass: 'image-grid__image-wrapper--is-viewable'
				}, options.offscreenImages.options));

			}

		}

		if (options.infiniteScroll.enable) {

			setupInitialState();
			saveLastItemIndex();
			// Wait 1 second after infinite content is injected before trying to lazy load
			// the images.
			debouncedLazyImageRefresh = debounce(lazyImageInstance.refresh, 1000);

			infiniteScrollWatcher = new ScrollWatch($.extend(true, {
				infiniteScroll: true,
				onInfiniteYInView: function() {

					console.log('infinite y in view...');

					defer(function() {

						var html = generateCells();

						if (html.length) {

							dom.grid.append(html);

							if (isMasonry()) {

								// Sort the newly injected cells.
								masonryInstance.addItems();

							}

							saveLastItemIndex();

							debouncedLazyImageRefresh();

							infiniteScrollWatcher.refresh();

							domCache.triggerCustomEvent({
								type: 'image_grid_cells_added'
							});

						}

					});

				}
			}, options.infiniteScroll.options));

		}

	};

	var destroy = function() {

		if (masonryInstance) {

			masonryInstance.destroy();
			masonryInstance = null;
		}

		if (focalPointInstance) {

			focalPointInstance.destroy();
			focalPointInstance = null;

		}

		if (offscreenImagesWatcher) {

			offscreenImagesWatcher.destroy();
			offscreenImagesWatcher = null;

		}

		if (infiniteScrollWatcher) {

			infiniteScrollWatcher.destroy();
			debouncedLazyImageRefresh.cancel();
			infiniteScrollWatcher = null;
			debouncedLazyImageRefresh = null;

		}

		domCache.document.off('.system-image-grid-' + instanceId);
		lazyImageInstance.destroy();
		lazyImageInstance = null;
		dom = null;
		options = null;
		state = null;
		instanceId = null;

	};

	init();

	return {
		// no init because the factory function becomes the init
		destroy: destroy,
		getItemIndex: getItemIndex,
		getItemByIndex: getItemByIndex,
		getItems: getItems,
		getData: getData,
		isMasonry: isMasonry
	};

};

// export a factory function instead of an object
module.exports = imageGrid;
