'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var domCache = require('dom-cache');
var fullscreenSlideshow = require('fullscreen-slideshow');
var pageBuilderWidget = require('page-builder-widget');

var instanceCount = 0;

var create = function(pageBuilderWidgetId, imageGridData) {

	var dom;
	var fullscreenSlideshowInstance;

	var onFullscreenSlideshowCaptionClick = function(e) {

		// Open in new tab if we're not linking to an internal page
		var target = e.imgData.linkMenuId ? '_self' : '_blank';

		window.open(e.imgData.linkUrl, target);

	};

	var onPageBuilderWidgetSlideshowRequested = function(e, data) {

		fullscreenSlideshowInstance.setData(data.imageGridInstanceData);
		fullscreenSlideshowInstance.launch(data.itemIndex);

	};

	var setupDom = function() {

		dom = {
			pageBuilderWidget: $('#pageBuilderWidget' + pageBuilderWidgetId)
		};

	};

	var addEventHandlers = function() {

		dom.pageBuilderWidget.on('page_builder_widget_slideshow_requested', onPageBuilderWidgetSlideshowRequested);

	};

	var init = function() {

		console.log('initing page-builder-widget-factory.js', arguments);

		instanceCount++;
		setupDom();
		addEventHandlers();
		pageBuilderWidget.create(pageBuilderWidgetId, imageGridData);

		// Since this module could get inited multiple times (example: multiple blog posts being shown in full on the full post layout), we need to make sure we only perform certain operations once per page load
		if (instanceCount === 1) {

			// This is the first time an instance is being created, setup the fullscreen-slideshow module

			fullscreenSlideshow.init();

			domCache.document.on('fullscreen_slideshow_caption_click', onFullscreenSlideshowCaptionClick);

		}

		fullscreenSlideshowInstance = fullscreenSlideshow.create({
			target: '#fullscreenSlideshow' + pageBuilderWidgetId
		});

	};

	init();

};

// Needs to be a factory to support multiple posts showing up on the same page (full post layout)
module.exports = {
	create: create
};
